import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { prettyDatetime } from '@/helpers/prettyPrint'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useABTestsStore = defineStore ({
  id:'ABTests', 
  state: () => ({
    testId: '',
    ABTests: [],
    testingReport: {},
    recipientsConfig: {
      testName: 'Новый А/В тест',
      companyId: '',
      segmentId: '',
      excludeSegmentId: []
    },
    testSettingConfig: {
      whatTesting: '',
      audienceTesting: '10',
      winnerParametr: '',
      durationTestingHours: '',
      durationTestingMinuts: '',
    },
    settingLetterConfig: {
      letterTheme: '',
      letterThemeV2: '',
      senderAddressId: '',
      senderName: '',
      senderAddressIdV2: '',
      senderNameV2: '',
      letterPreheader: '',
      letterPreheaderV2: '',
    },
    template: {
      templateId: '',
      HTMLbody: '',
      templateHtmlCode: '',
      templateCreatedAt: '',
      templateName: '',
      templateIdV2: '',
      HTMLbodyV2: '',
      templateHtmlCodeV2: '',
      templateCreatedAtV2: '',
      templateNameV2: ''
    },
    startTestConfig: {
      startType: '',
      dateOfStart: ''
    },
    finalCampaignStartType: 'Now',
    finalCampaignStartAt: '',
    ABTestsCount: '',
    testName: '',
    testState: '',
    testingObject: '',
    determingWinner: '',
    testSegmentName: '',
    testSegmentId: '',
    testingStartDate: '',
    testingEndDate: '',
    testingDurationHours: '',
    testingDurationMinutes: '',
    testsCurrentPage: 1,
    testsPerPage: 20,
    testsLastPage: 1,
    isLoading: false
  }),
  getters: {
    abTestsData: (state) => {
      let abTestsData = {}
     
      if (state.recipientsConfig.testName) {
        abTestsData['name'] = state.recipientsConfig.testName
      }
      if (state.recipientsConfig.companyId) {
        abTestsData['company_id'] = parseInt(state.recipientsConfig.companyId)
      }
      if (state.recipientsConfig.segmentId) {
        abTestsData['cdp_segment_id'] = parseInt(state.recipientsConfig.segmentId)
      }
      if (state.recipientsConfig.excludeSegmentId) {
        abTestsData['excluded_segment_ids'] = state.recipientsConfig.excludeSegmentId
      }
      if (state.testSettingConfig.whatTesting) {
        abTestsData['kind'] = state.testSettingConfig.whatTesting
        abTestsData['options_variant_a'] = {
            
        }
        abTestsData['options_variant_b'] = {
            
        }
        if (state.testSettingConfig.whatTesting == 'Subject') {
          abTestsData['options_variant_a'].subject = state.settingLetterConfig.letterTheme
          abTestsData['options_variant_b'].subject = state.settingLetterConfig.letterThemeV2
        } else {
          abTestsData['subject'] = state.settingLetterConfig.letterTheme
        }
        if (state.testSettingConfig.whatTesting == 'SenderName') {
          abTestsData['options_variant_a'].email_sender_name_id = parseInt(state.settingLetterConfig.senderAddressId)
          abTestsData['options_variant_a'].email_sender_name_name = state.settingLetterConfig.senderName
          abTestsData['options_variant_b'].email_sender_name_id = parseInt(state.settingLetterConfig.senderAddressIdV2)
          abTestsData['options_variant_b'].email_sender_name_name = state.settingLetterConfig.senderNameV2
        } else {
          abTestsData['email_sender_name_id'] = parseInt(state.settingLetterConfig.senderAddressId)
          abTestsData['email_sender_name_name'] = state.settingLetterConfig.senderName
        }

        if (state.testSettingConfig.whatTesting == 'Preheader') {
          abTestsData['options_variant_a'].preheader = state.settingLetterConfig.letterPreheader
          abTestsData['options_variant_b'].preheader = state.settingLetterConfig.letterPreheaderV2
        } else {
          abTestsData['preheader'] = state.settingLetterConfig.letterPreheader
        }
        if (state.testSettingConfig.whatTesting == 'Template') {
          if (state.template.templateId) {
            abTestsData['options_variant_a'].email_template_id = parseInt(state.template.templateId)
            abTestsData['options_variant_a'].email_template_body = state.template.templateHtmlCode
            abTestsData['options_variant_a'].email_template_name = state.template.templateName
          } 
          if (state.template.HTMLbody) {
            abTestsData['options_variant_a'].email_template_body = state.template.HTMLbody
          } 
          if (state.template.templateIdV2) {
            abTestsData['options_variant_b'].email_template_id = parseInt(state.template.templateIdV2)
            abTestsData['options_variant_b'].email_template_body = state.template.templateHtmlCodeV2
            abTestsData['options_variant_b'].email_template_name = state.template.templateNameV2
          } 
          if (state.template.HTMLbodyV2) {
            abTestsData['options_variant_b'].email_template_body = state.template.HTMLbodyV2
          }
        } else {
          if (state.template.templateId) {
            abTestsData['email_template_id'] = parseInt(state.template.templateId)
          }
          if (state.template.templateHtmlCode) {
            abTestsData['email_template_body'] = state.template.templateHtmlCode
          }
          if (state.template.HTMLbody) {
            abTestsData['email_template_body'] = state.template.HTMLbody
          }
          if (state.template.templateName) {
            abTestsData['email_template_name'] = state.template.templateName
          }
        }
      }
      if (state.testSettingConfig.audienceTesting) {
        abTestsData['audience'] = parseInt(state.testSettingConfig.audienceTesting)
      }
      if (state.testSettingConfig.winnerParametr) {
        abTestsData['winner_kind'] = state.testSettingConfig.winnerParametr
      }
      if (state.testSettingConfig.durationTestingHours !== null || state.testSettingConfig.durationTestingHours !== '') {
        abTestsData['duration_hours'] = state.testSettingConfig.durationTestingHours
      }
      if (state.testSettingConfig.durationTestingMinuts !== null || state.testSettingConfig.durationTestingMinuts !== '') {
        abTestsData['duration_minutes'] = state.testSettingConfig.durationTestingMinuts
      }
      if (state.testSettingConfig.durationTestingMinuts) {
        abTestsData['duration_minutes'] = state.testSettingConfig.durationTestingMinuts
      }
      if (state.startTestConfig.startType)  {
        abTestsData['start_testing_kind'] = state.startTestConfig.startType
      }
      if (state.startTestConfig.dateOfStart) {
        abTestsData['start_testing_at'] = state.startTestConfig.dateOfStart
      }

      return abTestsData
    },
    finalCampaignData: (state) => {
      let finalCampaignData = {}

      if (state.finalCampaignStartType) {
        finalCampaignData['final_campaign_start_kind'] = state.finalCampaignStartType
      }
      if (state.finalCampaignStartAt) {
        finalCampaignData['final_campaign_start_at'] = state.finalCampaignStartAt
      }
      
      return finalCampaignData
    },
    preparedABTests: (state) => state.ABTests.map(test => {
      return {
        id: test.id.toString(),
        name: test.name,
        createdAt: test.created_at !== null ? test.created_at : '',
        state: test.state,
        segmentName: test?.cdp_segment_name || '-',
        ownerName: test?.author_name || '',
        recipientsCount: test?.addressees_count || 0,
        variants: test.variants_count || 0
      }
    }),
  },
  actions: {
    setABTestsDefault() {
      this.recipientsConfig.testName = 'Новый А/В тест'
      this.recipientsConfig.companyId = ''
      this.recipientsConfig.segmentId = ''
      this.recipientsConfig.excludeSegmentId = []

      this.testSettingConfig.whatTesting = ''
      this.testSettingConfig.audienceTesting = '10'
      this.testSettingConfig.winnerParametr = ''
      this.testSettingConfig.durationTestingHours = ''
      this.testSettingConfig.durationTestingMinuts = ''

      this.settingLetterConfig.letterTheme = ''
      this.settingLetterConfig.letterThemeV2 = ''
      this.settingLetterConfig.senderAddressId = ''
      this.settingLetterConfig.senderName = ''
      this.settingLetterConfig.senderAddressIdV2 = ''
      this.settingLetterConfig.senderNameV2 = ''
      this.settingLetterConfig.letterPreheader = ''
      this.settingLetterConfig.letterPreheaderV2 = ''

      this.template.templateId = ''
      this.template.HTMLbody = ''
      this.template.templateHtmlCode = ''
      this.template.templateCreatedAt = ''
      this.template.templateName = ''
      this.template.templateIdV2 = ''
      this.template.HTMLbodyV2 = ''
      this.template.templateHtmlCodeV2 = ''
      this.template.templateCreatedAtV2 = ''
      this.template.templateNameV2 = ''

      this.startTestConfig.startType = ''
      this.startTestConfig.dateOfStart = ''
    },
    parseABTestResponse(response) {
      this.recipientsConfig.testName = response.name
      this.recipientsConfig.companyId = response.company_id.toString()
      this.recipientsConfig.segmentId = response.cdp_segment_id.toString()
      this.recipientsConfig.excludeSegmentId = response.excluded_segment_ids

      this.testSettingConfig.whatTesting = response.kind
      this.testSettingConfig.audienceTesting = response.audience
      this.testSettingConfig.winnerParametr = response.winner_kind
      this.testSettingConfig.durationTestingHours = response.duration_hours
      this.testSettingConfig.durationTestingMinuts = response.duration_minutes

      if (response.kind == 'Subject') {
        this.settingLetterConfig.letterTheme = response.options_variant_a.subject
        this.settingLetterConfig.letterThemeV2 = response.options_variant_b.subject
      } else {
        this.settingLetterConfig.letterTheme = response.subject
      }
      if (response.kind == 'SenderName') {
        this.settingLetterConfig.senderAddressId = response.options_variant_a.email_sender_name_id.toString()
        this.settingLetterConfig.senderName = response.options_variant_a.email_sender_name_name
        this.settingLetterConfig.senderAddressIdV2 = response.options_variant_b.email_sender_name_id.toString()
        this.settingLetterConfig.senderNameV2 = response.options_variant_b.email_sender_name_name
      } else {
        this.settingLetterConfig.senderAddressId = response.email_sender_name_id
        this.settingLetterConfig.senderName = response.email_sender_name_name
      }
      if (response.kind == 'Preheader') {
        this.settingLetterConfig.letterPreheader = response.options_variant_a.preheader
        this.settingLetterConfig.letterPreheaderV2 = response.options_variant_b.preheader
      } else {
        this.settingLetterConfig.letterPreheader = response.preheader
      }
      if (response.kind == 'Template') {
        this.template.templateId = (response.options_variant_a.email_template_id === null || response.options_variant_a.email_template_id === undefined) ? '' : response.options_variant_a.email_template_id.toString()
        this.template.HTMLbody = response.options_variant_a.email_template_body
        this.template.templateHtmlCode = response.options_variant_a.email_template_body
        this.template.templateName = response.options_variant_a.email_template_name
        this.template.templateIdV2 = (response.options_variant_b.email_template_id === null || response.options_variant_b.email_template_id === undefined) ? '' : response.options_variant_b.email_template_id.toString()
        this.template.HTMLbodyV2 = response.options_variant_b.email_template_body
        this.template.templateHtmlCodeV2 = response.options_variant_b.email_template_body
        this.template.templateNameV2 = response.options_variant_b.email_template_name
      } else {
        this.template.templateId = (response.email_template_id === null || response.email_template_id === undefined) ? '' : response.email_template_id.toString()
        this.template.HTMLbody = response.email_template_body
        this.template.templateHtmlCode = response.email_template_body
        this.template.templateName = response.email_template_name
      }

      this.startTestConfig.startType = response.start_testing_kind
      if (response.start_testing_at) {
        this.startTestConfig.dateOfStart = prettyDatetime(response.start_testing_at) 
      } else {
        this.startTestConfig.dateOfStart = ''
      }
    },
    async getABTests(currentPerPage) {
      let perPage = currentPerPage || this.testsPerPage
      this.isLoading = true
      axios
        .get('api/v1/ab_tests', { page: this.campaignsCurrentPage, per: perPage })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.ABTests = response.data.data
            this.ABTestsCount = response.data.total
            this.testsCurrentPage = response.data.current_page
            this.testsLastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getABTest(callback = {}) {
      this.isLoading = true
      axios
        .get(`api/v1/ab_tests/${this.testId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.testName = response.data.name
            this.testSegmentId = response.data.cdp_segment_id
            this.testState = response.data.state
            this.testingObject = response.data.kind
            this.determingWinner = response.data.winner_kind
            this.testSegmentName = response.data.cdp_segment_name
            this.testingStartDate = response.data.created_at
            this.testingEndDate = response.data.finished_at
            this.testingDurationHours = response.data.duration_hours
            this.testingDurationMinutes = response.data.duration_minutes
            this.finalCampaignStartType = response.data?.final_campaign_start_kind || ''
            if (response.data?.final_campaign_start_at) {
              this.finalCampaignStartAt = prettyDatetime(response.data?.final_campaign_start_at)
            }
            this.testingReport = response.data
            this.parseABTestResponse(response.data)
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createABTest() {
      this.isLoading = true
      axios
        .post('api/v1/ab_tests', this.abTestsData)
        .then((response) => {
          if (response.status === 201 && response.data.ab_test) {
            if (response.data.ab_test.state !== 'Draft') {
              this.startABTest(response.data.ab_test.id)
            }
            router.push({name: 'newsletter_email_tests'})
            toast.success('A/B тест успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async saveABTestToTemplate() {
      this.isLoading = true
      axios
        .post('api/v1/ab_tests', this.abTestsData)
        .then((response) => {
          if (response.status === 201 && response.data.ab_test) {
            router.push({name: 'newsletter_email_tests'})
            toast.success('A/B тест успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateABTest() {
      this.isLoading = true
      axios
        .post(`api/v1/ab_tests/${this.testId}`, this.abTestsData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('A/B тест успешно обновлен')
            router.push({name: 'newsletter_email_tests'})
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteABtest(payload) {
      if (confirm("Вы действительно хотите удалить?")) {
        let testId = ''
        if (typeof payload == 'object' || payload === undefined) {
          testId  = parseInt(this.testId)
        } else {
          testId = parseInt(payload)
        }
        this.isLoading = true
        axios
          .delete(`api/v1/ab_tests/${testId}`)
          .then((response) => {
            if ((response.status === 200 || response.status === 201)) {
              this.getABTests()
              router.push({name: 'newsletter_email_tests'})
              toast.success('A/B тест успешно удален')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async startABTest(payload) {
      let testId = ''
      if (typeof payload == 'object' || payload === undefined) {
        testId = parseInt(this.testId)
      } else {
        testId = parseInt(payload)
      }
      this.testId = testId
      this.isLoading = true
      axios
        .post(`api/v1/ab_tests/${testId}/start`)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('A/B тест успешно запущен')
            this.getABTests()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async startFinalCampaign(payload) {
      let campaignId = ''
      
      campaignId = parseInt(payload)
      this.isLoading = true
      axios
        .post(`api/v1/ab_tests/${campaignId}/start_final_campaign`)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Финальная кампания успешно запущена')
            this.getABTest()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateFinalCampaign(payload) {
      let campaignId = ''
      
      campaignId = parseInt(payload)
      this.isLoading = true
      axios
        .post(`api/v1/ab_tests/${campaignId}/update_final_campaign_start`, this.finalCampaignData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Финальная кампания успешно обновлена')
            this.getABTest()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async selectWinnerVariant(payload) {
      let variantId = ''
      variantId = parseInt(payload)
      this.isLoading = true
      axios
        .post(`api/v1/ab_tests/${this.testId}/select_winner`, { winner_id: variantId})
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Победитель успешно выбран')
            this.getABTest()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
  }
})