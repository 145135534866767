import { defineStore } from 'pinia'
import { useUsersStore } from '@/stores/users'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"

const toast = useToast()

export const useCompaniesStore = defineStore({
  id: 'companies',
  state: () => ({
    companies: [],
    companyPage: 1,
    currentCompany: 0,
    name: '',
    registrationDate: '',
    entity: '',
    numberContract: '',
    responsible: '',
    currency: '',
    overdraft: 0,
    minBalance: '',
    paymentType: '',
    enabled: 'false',
    createdAt: '',
    organizationName: '',
    legalAddress: '',
    postAddress: '',
    inn: '',
    kpp: '',
    bik: '',
    correspondentAccountNumber: '',
    accountNumber: '',
    ogrn: '',
    companyUsersCount: 0,
    tariff: [],
    tariffPrice: 0,
    checks: [],
    checkId: 0,
    checkPayer: '',
    checkDate: '',
    checkPayment: 'cashless',
    checkPrice: 0,
    checkStatus: 'Pending',
    checkEmail: '',
    balance: 0,
    currentServiceProviderLogin: '',
    serviceProviderLogin: '',
    serviceProviderPassword: '',
    newServiceProviderPassword: '',
    serviceProviderRoute: '',
    serviceProviderRoutePush: '',
    serviceProviderRouteViber: '',
    currentPage: 1,
    perPage: 20,
    lastPage: 1,
    isLoading: false,
    isCompaniesLoading: false,
    isUsersLoading: false,
    isTariffLoading: true
  }),
  getters: {
    companyData: (state) => {
      let companyData = {}
      if(state.enabled) {
        companyData['enabled'] = state.enabled == 'true' ? true : false
      }
      if(state.name) {
        companyData['name'] = state.name
      }
      if(state.entity) {
        companyData['legal_entity_kind'] = state.entity
      }
      /*if(state.registrationDate){
        companyData['registrationDate'] = state.registrationDate
      }*/
      if(state.numberContract) {
        companyData['contract_number'] = state.numberContract
      }
      if(state.responsible) {
        companyData['responsible_id'] = parseInt(state.responsible)
      } else {
        companyData['responsible_id'] = null
      }
      if(state.overdraft) {
        companyData['overdraft'] = state.overdraft
      }
      if(state.minBalance) {
        companyData['min_balance'] = state.minBalance
      }
      /*if(state.paymentType) {
        companyData['paymentType'] = state.paymentType
      }*/
      
      return companyData
    },
    checkData: (state) => {
      let checkData = {}
      if(state.checkPrice) {
        checkData['amount'] = parseInt(state.checkPrice)
      }
      if(state.checkStatus) {
        checkData['state'] = state.checkStatus
      }

      return checkData
    },
    tariffData: (state) => {
      let tariff = {}
      tariff['tariff_groups'] = []
      if (state.tariff.length > 0) {
        tariff['tariff_groups'] = state.tariff.map(group => {
          return {
            name: group.name,
            tariff_options: group.tariffOptions.map(option => {
              let preparedOption = {}
              preparedOption['name'] = option.name
              preparedOption['kind'] = option.kind
              preparedOption['enabled'] = option.enabled
              if (option.kind == 'Default') {
                preparedOption['price'] = parseFloat(option.price)
              } else if (option.kind == 'Step') {
                preparedOption['tariff_step_options'] = option.tariffStepOptions.map(currentStep => {
                  let step = {}
                  if (currentStep.to === '') {
                    step = {
                      from: parseInt(currentStep.from),
                      price: parseFloat(currentStep.price)
                    }
                  } else {
                    step = {
                      from: parseInt(currentStep.from),
                      to: parseInt(currentStep.to),
                      price: parseFloat(currentStep.price)
                    }
                  }
                  return step
                })  
              }

              return preparedOption
            })
          }
        })
      }

      return tariff
    },
    requisitesData: (state) => {
      let requisitesData = {}
      if(state.organizationName) {
        requisitesData['organization_name'] = state.organizationName
      }
      if(state.legalAddress) {
        requisitesData['legal_address'] = state.legalAddress
      }
      if(state.postAddress) {
        requisitesData['post_address'] = state.postAddress
      }
      if(state.inn) {
        requisitesData['inn'] = state.inn
      }
      if(state.kpp) {
        requisitesData['kpp'] = state.kpp
      }
      if(state.bik) {
        requisitesData['bik'] = state.bik
      }
      if(state.correspondentAccountNumber) {
        requisitesData['correspondent_account_number'] = state.correspondentAccountNumber
      }
      if(state.accountNumber) {
        requisitesData['account_number'] = state.accountNumber
      }
      if(state.ogrn) {
        requisitesData['ogrn'] = state.ogrn
      }

      return requisitesData
    },
    serviceProviderData: (state) => {
      let serviceProviderData = {}
      if (state.serviceProviderLogin) {
        serviceProviderData['login'] = state.serviceProviderLogin
      }
      if (state.serviceProviderPassword) {
        serviceProviderData['password'] = state.serviceProviderPassword
      }
      if (state.newServiceProviderPassword) {
        serviceProviderData['new_password'] = state.newServiceProviderPassword
      }
      if (state.serviceProviderRoute) {
        serviceProviderData['sms_route_group_id'] = state.serviceProviderRoute
      }
      if (state.serviceProviderRoutePush) {
        serviceProviderData['push_route_group_id'] = state.serviceProviderRoutePush
      }
      if (state.serviceProviderRouteViber) {
        serviceProviderData['viber_route_group_id'] = state.serviceProviderRouteViber
      }
      
      return serviceProviderData
    },
    preparedCompanies: (state) => state.companies.map(company => {
      return {
        value: company.id.toString(),
        text: company.name,
      }
    }),
    preparedEmailTemplateCompanies: (state) => state.companies.map(company => {
      return {
        id: company.id.toString(),
        value: company.email_builder_secret || '',
        text: company.name
      }
    }),
    preparedForStatsCompanies: (state) => {
      let companies = [{value: 'all', text: 'Все компании'}]
      let otherCompanies = state.companies.map(company => {
          return {
            value: company.id.toString(),
            text: company.name
          }
        })

      return [...companies, ...otherCompanies ]
    },
    preparedChecks: (state) => state.checks.map(check => {
      return {
        id: check.id.toString(),
        payer: state.name,
        createdAt: check.created_at,
        status: check.state == 'Pending' ? 'Не оплачен' : 'Оплачен',
        price: check.amount
      }
    }),
    paymentTypeScope: (state) => [
      {
        value: 'Prepayment',
        text: 'Предоплата'
      },
      {
        value: 'Postpayment',
        text: 'Постоплата'
      }
    ],
    entityScope: (state) => [
      {
        value: 'LimitedLiabilityCompany',
        text: 'ООО'
      },
      {
        value: 'Partnership',
        text: 'Партнёрство'
      }
    ]
  },
  actions:{
    setCompanyDefault() {
      this.name = ''
      this.registrationDate = ''
      this.entity = ''
      this.numberContract = ''
      this.responsible = ''
      this.currency = ''
      this.overdraft = 0
      this.paymentType = ''
      this.enabled = 'false'
      this.companyUsersCount = 0
      this.tariff = []
      this.tariffPrice = 0
    },
    setCompaniesPaginationDefault() {
      this.currentPage = 1
      this.perPage = 20
      this.lastPage = 1
    },
    setCheckDefault() {
      this.checkPayer = ''
      this.checkDate = ''
      this.checkPayment = 'cashless'
      this.checkPrice = 0
      this.checkStatus = 'Pending'
      this.checkEmail = ''
    },
    setRequisitesDefault() {
      this.organizationName = ''
      this.legalAddress = ''
      this.postAddress = ''
      this.inn = ''
      this.kpp = ''
      this.bik = ''
      this.correspondentAccountNumber = ''
      this.accountNumber = ''
      this.ogrn = ''
    },
    setServiceProviderDefault() {
      this.currentServiceProviderLogin = ''
      this.serviceProviderLogin = ''
      this.serviceProviderPassword = ''
      this.serviceProviderRoute = ''
      this.serviceProviderRoutePush = ''
      this.serviceProviderRouteViber = ''
    },
    preparedTariff() {
      let tariff = []
      if (this.tariff.length > 0) {
        tariff = this.tariff.map(group => {
          return {
            id: group.id * (-1),
            name: group.name,
            tariffOptions: group.tariff_options.map(option => {
              let preparedOption = {}
              preparedOption['id'] = option.id * (-1)
              preparedOption['name'] = option.name
              preparedOption['kind'] = option.kind
              preparedOption['enabled'] = option.enabled
              if (option.kind == 'Default') {
                preparedOption['price'] = option.price
              } else if (option.kind == 'Step') {
                if (option.tariff_step_options.length > 0) { 
                  preparedOption['price'] = option.calculatedPrice
                } else {
                  preparedOption['price'] = 0
                }
                preparedOption['tariffStepOptions'] = option.tariff_step_options.map((step, index) => {
                  return {
                    id: step.id * (-1),
                    from: step.from,
                    to: step.to === null ? '' : step.to,
                    price: step.price,
                    number: index + 1
                  }
                })  
              }

              return preparedOption
            })
          }
        })
      }

      return tariff
    },
    async getCompanies(currentPerPage, callback = {}) {
      let perPage = currentPerPage || this.currentPerPage
      this.isCompaniesLoading = true
      axios
        .get('api/v1/companies', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.companies = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
            if (typeof callback == 'function') {
              callback('success', response.data.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', [])
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        })
        .finally((response) => {
          this.isCompaniesLoading = false
        })
    },
    async getCompany(payload = {}, callback = {}) {
      let companyId = ''

      if (typeof payload == 'object') {
        companyId = parseInt(this.currentCompany)
      } else {
        companyId = parseInt(payload)
      }
      this.isLoading = true
      axios
        .get(`api/v1/companies/${companyId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.name = response.data.name
            this.entity = response.data.legal_entity_kind
            this.registrationDate = response.data.created_at
            this.numberContract = response.data.contract_number
            if (response.data.responsible_id !== null && response.data.responsible_id !== undefined) {
              this.responsible = response.data.responsible_id.toString()
            }
            if (response.data.enabled !== null && response.data.enabled !== undefined) {
              this.enabled = response.data.enabled.toString()
            }
            this.overdraft = response.data.overdraft
            this.minBalance = response.data.min_balance
            this.paymentType = response.data.payment_type
            this.createdAt = response.data.created_at
            this.companyUsersCount = response.data.users_count
            this.balance = response.data.balance
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
            //this.getCompanyUsers()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
            if (typeof callback == 'function') {
              callback('failed', {})
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (typeof callback == 'function') {
            callback('failed', {})
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCompanyUsers(currentPerPage) {
      let perPage = currentPerPage || this.currentPerPage
      this.isUsersLoading = true
      axios
        .get(`api/v1/companies/${this.currentCompany}/users`, { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            const users = useUsersStore()
            users.users = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isUsersLoading = false
        })
    },
    async createCompany(callback = {}) {
      let companyData = this.companyData
      delete companyData.responsible_id
      this.isLoading = true
      axios
        .post('api/v1/companies', companyData)
        .then((response) => {
          if (response.status === 201 && response.data.company) {
            this.getCompanies()
            this.setCompanyDefault()
            toast.success('Компания успешно создана')
            if (typeof callback == 'function') {
              callback('success', response.data)
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCompany(){
      axios
        .post(`api/v1/companies/${this.currentCompany}`, this.companyData)
        .then((response) => {
          if (response.status === 200 && response.data.company) {
            this.name = response.data.company.name
            this.entity = response.data.company.legal_entity_kind
            this.registrationDate = response.data.company.created_at
            this.numberContract = response.data.company.contract_number
            if (response.data.company.responsible_id !== null && response.data.company.responsible_id !== undefined) {
              this.responsible = response.data.company.responsible_id.toString()
            }
            this.overdraft = response.data.company.overdraft
            this.minBalance = response.data.company.min_balance
            this.paymentType = response.data.company.payment_type
            this.enabled = response.data.company.enabled.toString()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async deleteCompany() {
      if (confirm("Вы действительно хотите удалить?")) {
        this.isLoading = true
        axios
          .delete(`api/v1/companies/${this.currentCompany}`)
          .then((response) => {
            if (response.status === 200 && response.data.company) {
              router.push({name: 'administration_companies'})
              toast.success('Компания успешно удалена')
            } else {
              console.log(response)
              toast.error('Произошла ошибка')
            }
          })
          .catch((error) => {
            console.log(error)
            toast.error(error)
          })
          .finally((response) => {
            this.isLoading = false
          })
      }
    },
    async getMyCompany() {
      this.isLoading = true
      axios
        .get('api/v1/my_company')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.currentCompany = response.data.id
            this.name = response.data.name
            this.entity = response.data.legal_entity_kind
            this.registrationDate = response.data.created_at
            this.numberContract = response.data.contract_number
            if (response.data.responsible_id !== null && response.data.responsible_id !== undefined) {
              this.responsible = response.data.responsible_id.toString()
            }
            this.overdraft = response.data.overdraft
            this.paymentType = response.data.payment_type
            this.enabled = response.data.enabled.toString()
            this.createdAt = response.data.created_at
            if (response.data.tariff !== null && response.data.tariff !== undefined) {
              this.tariffPrice = response.data.tariff
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyUsers(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/my_company/users', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            const users = useUsersStore()
            users.users = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyTariff() {
      this.isTariffLoading = true
      axios
        .get('/api/v1/my_company/tariff')
        .then((response) => {
          if (response.status === 200 && response.data.tariff_groups) {
            this.tariff = response.data.tariff_groups
            this.tariff = this.preparedTariff()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          //console.log(error)
          this.tariff = []
        })
        .finally((response) => {
          this.isTariffLoading = false
        })
    },
    async getTariff() {
      this.isTariffLoading = true
      axios
        .get(`api/v1/companies/${this.currentCompany}/tariff`)
        .then((response) => {
          if (response.status === 200 && response.data.tariff_groups) {
            this.tariff = response.data.tariff_groups
            this.tariff = this.preparedTariff()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          //console.log(error)
          this.tariff = []
        })
        .finally((response) => {
          this.isTariffLoading = false
        })
    },
    async saveTariff() {
      this.isTariffLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/tariff`, this.tariffData)
        .then((response) => {
          if (response.status === 201 && response.data.tariff) {
            toast.success('Тариф успешно сохранён')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isTariffLoading = false
        })
    },
    async getDefaultTariff() {
      this.isTariffLoading = true
      axios
        .get('api/v1/default_tariff')
        .then((response) => {
          if (response.status === 200) {
            if (response.data.hasOwnProperty('tariff_groups')) {
              this.tariff = response.data.tariff_groups
              this.tariff = this.preparedTariff()
            } else {
              this.tariff = []
            }
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          //console.log(error)
          this.tariff = []
        })
        .finally((response) => {
          this.isTariffLoading = false
        })
    },
    async saveDefaultTariff() {
      this.isTariffLoading = true
      axios
        .post('api/v1/default_tariff', this.tariffData)
        .then((response) => {
          if (response.status === 200 && response.data.tariff) {
            toast.success('Тариф по умолчанию успешно сохранён')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isTariffLoading = false
        })
    },
    async getChecks(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get(`api/v1/companies/${this.currentCompany}/invoices`, { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.checks = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyChecks(currentPerPage) {
      let perPage = currentPerPage || this.perPage
      this.isLoading = true
      axios
        .get('api/v1/my_company/invoices', { params: { page: this.currentPage, per: perPage }})
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            this.checks = response.data.data
            this.currentPage = response.data.current_page
            this.lastPage = response.data.last_page
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCheck() {
      this.isLoading = true
      axios
        .get(`api/v1/companies/${this.currentCompany}/invoices/${this.checkId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.checkPayer = this.name
            this.checkStatus = response.data.state
            this.checkPrice = response.data.amount
            this.checkDate = response.data.created_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyCheck() {
      this.isLoading = true
      axios
        .get(`api/v1/my_company/invoices/${this.checkId}`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.checkPayer = this.name
            this.checkStatus = response.data.state
            this.checkPrice = response.data.amount
            this.checkDate = response.data.created_at
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createCheck() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/invoices`, this.checkData)
        .then((response) => {
          if (response.status === 201 && response.data.invoice) {
            this.getChecks()
            this.setCheckDefault()
            toast.success('Счёт успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createMyCompanyCheck() {
      this.isLoading = true
      axios
        .post('api/v1/my_company/invoices', this.checkData)
        .then((response) => {
          if (response.status === 201 && response.data.invoice) {
            this.getChecks()
            this.setCheckDefault()
            toast.success('Счёт успешно создан')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateCheck() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/invoices/${this.checkId}`, this.checkData)
        .then((response) => {
          if (response.status === 200 && response.data.invoice) {
            this.checkPayer = this.name
            this.checkStatus = response.data.invoice.state
            this.checkPrice = response.data.invoice.amount
            this.checkDate = response.data.invoice.created_at
            this.isLoading = false
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getCheckPDF(payload) {
      let checkId = ''
      if (typeof payload == 'object' || payload === undefined) {
        checkId  = parseInt(this.checkId)
      } else {
        checkId = parseInt(payload)
      }
      this.isLoading = true
      axios({
        method: 'GET',
        url: `api/v1/companies/${this.currentCompany}/invoices/${checkId}/download_pdf`,
        responseType: 'blob'
      }).then((response) => {
          if (response.status === 200 && response.data) {
            console.log(response)
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            let linkUrl = document.createElement('a')
            console.log(fileUrl)
            linkUrl.href = fileUrl
            linkUrl.setAttribute('download', 'invoice.pdf')
            document.body.appendChild(linkUrl)
            linkUrl.click()
            linkUrl.remove()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyCheckPDF(payload) {
      let checkId = ''
      if (typeof payload == 'object' || payload === undefined) {
        checkId  = parseInt(this.checkId)
      } else {
        checkId = parseInt(payload)
      }
      this.isLoading = true
      axios({
        method: 'GET',
        url: `api/v1/my_company/invoices/${checkId}/download_pdf`,
        responseType: 'blob'
      }).then((response) => {
          if (response.status === 200 && response.data) {
            console.log(response)
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            let linkUrl = document.createElement('a')
            console.log(fileUrl)
            linkUrl.href = fileUrl
            linkUrl.setAttribute('download', 'invoice.pdf')
            document.body.appendChild(linkUrl)
            linkUrl.click()
            linkUrl.remove()
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async sendInvoiceEmail() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/invoices/${this.checkId}/send_via_email`, {email: this.checkEmail})
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log(response)
            toast.success('Письмо успешно отправлено')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async sendMyCompanyInvoiceEmail() {
      this.isLoading = true
      axios
        .post(`api/v1/my_company/invoices/${this.checkId}/send_via_email`, {email: this.checkEmail})
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log(response)
            toast.success('Письмо успешно отправлено')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getRequisites() {
      this.isLoading = true
      axios
        .get(`/api/v1/companies/${this.currentCompany}/requisites`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.organizationName = response.data.organization_name
            this.legalAddress = response.data.legal_address
            this.postAddress = response.data.post_address
            this.inn = response.data.inn
            this.kpp = response.data.kpp
            this.bik = response.data.bik
            this.correspondentAccountNumber = response.data.correspondent_account_number
            this.accountNumber = response.data.account_number
            this.ogrn = response.data.ogrn
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateRequisites() {
      axios
        .post(`/api/v1/companies/${this.currentCompany}/requisites`, this.requisitesData)
        .then((response) => {
          if (response.status === 201 && response.data.requisites) {
            this.organizationName = response.data.requisites.organization_name
            this.legalAddress = response.data.requisites.legal_address
            this.postAddress = response.data.requisites.post_address
            this.inn = response.data.requisites.inn
            this.kpp = response.data.requisites.kpp
            this.bik = response.data.requisites.bik
            this.correspondentAccountNumber = response.data.requisites.correspondent_account_number
            this.accountNumber = response.data.requisites.account_number
            this.ogrn = response.data.requisites.ogrn
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async getMyCompanyRequisites() {
      this.isLoading = true
      axios
        .get('/api/v1/my_company/requisites')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.organizationName = response.data.organization_name
            this.legalAddress = response.data.legal_address
            this.postAddress = response.data.post_address
            this.inn = response.data.inn
            this.kpp = response.data.kpp
            this.bik = response.data.bik
            this.correspondentAccountNumber = response.data.correspondent_account_number
            this.accountNumber = response.data.account_number
            this.ogrn = response.data.ogrn
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateMyCompanyRequisites() {
      axios
        .post('/api/v1/my_company/requisites', this.requisitesData)
        .then((response) => {
          if (response.status === 201 && response.data.requisites) {
            this.organizationName = response.data.requisites.organization_name
            this.legalAddress = response.data.requisites.legal_address
            this.postAddress = response.data.requisites.post_address
            this.inn = response.data.requisites.inn
            this.kpp = response.data.requisites.kpp
            this.bik = response.data.requisites.bik
            this.correspondentAccountNumber = response.data.requisites.correspondent_account_number
            this.accountNumber = response.data.requisites.account_number
            this.ogrn = response.data.requisites.ogrn
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
    },
    async getServiceProvider() {
      this.isLoading = true
      axios
        .get(`api/v1/companies/${this.currentCompany}/sms_traffic_service_providers`)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.currentServiceProviderLogin = response.data?.login || ''
            this.serviceProviderRoute = response.data?.sms_route_group_id || ''
            this.serviceProviderRoutePush = response.data?.push_route_group_id || ''
            this.serviceProviderRouteViber = response.data?.viber_route_group_id || ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createServiceProvider() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/sms_traffic_service_providers`, this.serviceProviderData)
        .then((response) => {
          if (response.status === 201 && response.data.sms_traffic_service_provider) {
            this.currentServiceProviderLogin = response.data.sms_traffic_service_provider.login
            toast.success('Аккаунт успешно подключен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response?.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error('Некорректный логин или пароль')
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateServiceProvider() {
      this.isLoading = true
      axios
        .post(`api/v1/companies/${this.currentCompany}/sms_traffic_service_providers/update`, this.serviceProviderData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Сервис-провайдер успешно обновлен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteServiceProvider() {
      this.isLoading = true
      axios
        .delete(`api/v1/companies/${this.currentCompany}/sms_traffic_service_providers`)
        .then((response) => {
          if (response.status === 200 && response.data.sms_traffic_service_provider) {
            this.currentServiceProviderLogin = ''
            toast.success('Аккаунт успешно отключен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async getMyCompanyServiceProvider() {
      this.isLoading = true
      axios
        .get('api/v1/my_company/sms_traffic_service_providers')
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.currentServiceProviderLogin = response.data?.login || ''
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async createMyCompanyServiceProvider() {
      this.isLoading = true
      axios
        .post('api/v1/my_company/sms_traffic_service_providers', this.serviceProviderData)
        .then((response) => {
          if (response.status === 201 && response.data.sms_traffic_service_provider) {
            this.currentServiceProviderLogin = response.data.sms_traffic_service_provider.login
            toast.success('Аккаунт успешно подключен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response?.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error('Некорректный логин или пароль')
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async updateMyCompanyServiceProvider() {
      this.isLoading = true
      axios
        .post(`api/v1/my_company/sms_traffic_service_providers/update`, this.serviceProviderData)
        .then((response) => {
          if ((response.status === 200 || response.status === 201)) {
            toast.success('Сервис-провайдер успешно обновлен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    },
    async deleteMyCompanyServiceProvider() {
      this.isLoading = true
      axios
        .delete('api/v1/my_company/sms_traffic_service_providers')
        .then((response) => {
          if (response.status === 200 && response.data.sms_traffic_service_provider) {
            this.currentServiceProviderLogin = ''
            toast.success('Аккаунт успешно отключен')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            console.log(error.response)
            let textError = ''
            for(let key in error.response.data){
              textError += (key + ': ')
              textError += error.response.data[key] + '\n\n'
            }
            toast.error(textError)
          }
        })
        .finally((response) => {
          this.isLoading = false
        })
    }
  }
})