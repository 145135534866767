import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import router from "@/router/index"
import { formPrettySyles, formPrettyHtml, formPrettyJs } from '@/helpers/formComponentPrettyPrint'

const toast = useToast()

export const useFormsStore = defineStore ({
  id: 'forms',
  state: () => ({
    formId: '',
    forms: [],
    formsCount: '',
    formName: 'Новая форма',
    companyId: '',
    settingFormFields: {
      content_title: 'Присоединяйся к нам!', 
      content_description: 'Подпишись на уведомления об акциях, чтобы узнавать о скидках первым!', 
      content_button_text: 'Подписаться', 
      contacts_source_id: '', 
      contacts_source_name: '', 
      grace_title: 'Спасибо за подписку!', 
      grace_text: 'Мы отправили письмо для подтверждения вашей подписки! Перейдите в ней по ссылке.', 
      politics_url: '',
      uid: ''
    },
    fieldsFormConfig: {
      email: {
        enabled: 'true',
        name: 'Email',
        placeholder: 'Введите Email',
        validations: ['email'],
        isRequired: '',
        doubleOpt: 'false',
        address: '',
        senderName: '',
        param: 'email',
        value: ''
      },
      phone: {
        enabled: 'false',
        name: 'Телефон',
        placeholder: 'Введите номер телефона',
        validations: ['phone'],
        isRequired: '',
        param: 'phone',
        value: ''
      },
      name: {
        enabled: 'false',
        name: 'Имя',
        placeholder: 'Имя',
        validations: [],
        isRequired: '',
        param: 'name',
        value: ''
      }
    },
    designFormFields: {
      window_bg_color: '#F5F6FBFF', 
      window_title_color: '#484964FF',
      window_description_color: '#484964FF', 
      fields_title_color: '#484964FF', 
      fields_description_color: '#484964FF', 
      button_bg_color: '#115EFBFF', 
      button_text_color: '#F5F6FBFF'
    },
    formsCurrentPage: 1,
    formsPerPage: 20,
    formsLastPage: 1,
    isLoading: false
  }),
  getters: {
    formData: (state) => {
      let formData = {} 
      
      if (state.formName) {
        formData['subscription_form_name'] = state.formName
      }

      if (state.companyId) {
        formData['company_id'] = state.companyId
      }

      if (state.settingFormFields.content_title) {
        formData['header'] = state.settingFormFields.content_title
      }

      if (state.settingFormFields.content_description) {
        formData['description'] = state.settingFormFields.content_description
      }

      if (state.settingFormFields.content_button_text) {
        formData['button_text'] = state.settingFormFields.content_button_text
      }

      if (state.settingFormFields.contacts_source_id) {
        formData['pixel_id'] = parseInt(state.settingFormFields.contacts_source_id)
      }
      
      if (state.settingFormFields.grace_title) {
        formData['ty_page_header'] = state.settingFormFields.grace_title
      }

      if (state.settingFormFields.grace_text) {
        formData['ty_page_text'] = state.settingFormFields.grace_text
      }

      if (state.settingFormFields.politics_url) {
        formData['data_policy_link'] = state.settingFormFields.politics_url
      }
      
      if (state.fieldsFormConfig.email.enabled) {
        formData['email'] = state.fieldsFormConfig.email.enabled === 'true' ? true : false
      }

      if (state.fieldsFormConfig.email.name) {
        formData['email_field_name'] = state.fieldsFormConfig.email.name
      }
      
      if (state.fieldsFormConfig.email.placeholder) {
        formData['email_placeholder'] = state.fieldsFormConfig.email.placeholder
      }
      
      if (state.fieldsFormConfig.email.isRequired) {
        formData['email_required'] = state.fieldsFormConfig.email.isRequired === 'true' ? true : false
      }
      
      if (state.fieldsFormConfig.email.doubleOpt) {
        formData['double_opt_in'] = state.fieldsFormConfig.email.doubleOpt === 'true' ? true : false
      }

      if (state.fieldsFormConfig.email.senderName) {
        formData['email_sender_id'] = parseInt(state.fieldsFormConfig.email.senderName)
      }

      if (state.fieldsFormConfig.phone.enabled) {
        formData['phone'] = state.fieldsFormConfig.phone.enabled === 'true' ? true : false
      }

      if (state.fieldsFormConfig.phone.name) {
        formData['phone_field_name'] = state.fieldsFormConfig.phone.name
      }
      
      if (state.fieldsFormConfig.phone.placeholder) {
        formData['phone_placeholder'] = state.fieldsFormConfig.phone.placeholder
      }
      
      if (state.fieldsFormConfig.phone.isRequired) {
        formData['phone_required'] = state.fieldsFormConfig.phone.isRequired === 'true' ? true : false
      }

      
      if (state.fieldsFormConfig.name.enabled) {
        formData['name'] = state.fieldsFormConfig.name.enabled === 'true' ? true : false
      }

      if (state.fieldsFormConfig.name.name) {
        formData['name_field_name'] = state.fieldsFormConfig.name.name
      }
      
      if (state.fieldsFormConfig.name.placeholder) {
        formData['name_placeholder'] = state.fieldsFormConfig.name.placeholder
      }
      
      if (state.fieldsFormConfig.name.isRequired) {
        formData['name_required'] = state.fieldsFormConfig.name.isRequired === 'true' ? true : false
      }
      
      if (state.designFormFields.window_bg_color) {
        formData['form_bg_color'] = state.designFormFields.window_bg_color
      }
      
      if (state.designFormFields.window_title_color) {
        formData['form_header_color'] = state.designFormFields.window_title_color
      }
      
      if (state.designFormFields.window_description_color ) {
        formData['form_desc_color'] = state.designFormFields.window_description_color 
      }

      if (state.designFormFields.fields_title_color ) {
        formData['fields_header_color'] = state.designFormFields.fields_title_color 
      }
      
      if (state.designFormFields.fields_description_color ) {
        formData['fields_desc_color'] = state.designFormFields.fields_description_color 
      }
      
      if (state.designFormFields.fields_description_color ) {
        formData['fields_desc_color'] = state.designFormFields.fields_description_color 
      }
      
      if (state.designFormFields.fields_description_color ) {
        formData['fields_desc_color'] = state.designFormFields.fields_description_color 
      }

      if (state.designFormFields.button_bg_color) {
        formData['btn_bg_color'] = state.designFormFields.button_bg_color
      }

      if (state.designFormFields.button_text_color) {
        formData['btn_text_color'] = state.designFormFields.button_text_color
      }
      
      return formData
    },
    testSendData: (state) => {
      let testSendData = {}

      if (state.fieldsFormConfig.email.value) {
        testSendData['email'] = state.fieldsFormConfig.email.value
      }
      
      if (state.fieldsFormConfig.phone.value) {
        testSendData['phone'] = state.fieldsFormConfig.phone.value
      }

      if (state.fieldsFormConfig.name.value) {
        testSendData['name'] = state.fieldsFormConfig.name.value
      }

      return testSendData
    },
    preparedForms: (state) => state.forms.map(form => {
      state.settingFormFields = {
        content_title: form.header, 
        content_description: form.description, 
        content_button_text: form.button_text, 
        contacts_source_id: form.pixel_id, 
        contacts_source_name: '',
        grace_title: form.ty_page_header, 
        grace_text: form.ty_page_text, 
        politics_url: form.data_policy_link,
        uid: form.uid
      }
      state.fieldsFormConfig = {
        email: {
          enabled: form.inputs.email,
          name: form.inputs.email_field_name,
          placeholder: form.inputs.email_placeholder,
          validations: ['email'],
          isRequired: form.email_required,
          doubleOpt: 'false',
          address: '',
          senderName: form.email_sender_id,
          param: 'email',
          value: ''
        },
        phone: {
          enabled: form.inputs.phone,
          name: form.inputs.phone_field_name,
          placeholder: form.inputs.phone_placeholder,
          validations: ['phone'],
          isRequired: form.phone_required,
          param: 'phone',
          value: ''
        },
        name: {
          enabled: form.inputs.name,
          name: form.inputs.name_field_name,
          placeholder: form.inputs.name_placeholder,
          validations: [],
          isRequired: form.name_required,
          param: 'name',
          value: ''
        }
      }
      state.designFormFields = {
        window_bg_color: form.design.form_bg_color, 
        window_title_color: form.design.form_header_color,
        window_description_color: form.design.form_desc_color, 
        fields_title_color: form.design.fields_header_color, 
        fields_description_color: form.design.fields_desc_color, 
        button_bg_color: form.design.btn_bg_color, 
        button_text_color: form.design.btn_text_color
      }
      let code = formPrettySyles(state.designFormFields) + formPrettyHtml(state.settingFormFields, state.fieldsFormConfig) + formPrettyJs(state.settingFormFields)
      
      return {
        id: form.id.toString(),
        name: form.subscription_form_name,
        createdAt: form.created_at !== null ? form.created_at : '',
        updatedAt: form.updated_at !== null ? form.updated_at : '',
        sourceName: form?.pixel_name || '-',
        ownerName: form?.author_name || '',
        code: code,
      }
    }),
  },
  actions: {
   async getFroms(currentPerPage) {
    let perPage = currentPerPage || this.testsPerPage
    this.isLoading = true
    axios
      .get('api/v1/subscription_forms', { page: this.testsCurrentPage, per: perPage })
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          this.forms = response.data.data
          this.formsCount = response.data.total
          this.testsCurrentPage = response.data.current_page
          this.testsLastPage = response.data.last_page
        } else {
          console.log(response)
          toast.error('Произошла ошибка')
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally((response) => {
        this.isLoading = false
      })
    },
   getForm() {
    this.isLoading = true
    axios
      .get(`api/v1/subscription_forms/${this.formId}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.companyId = response.data.company_id
          this.settingFormFields = {
            content_title: response.data.header, 
            content_description: response.data.description, 
            content_button_text: response.data.button_text, 
            contacts_source_id: response.data.pixel_id, 
            contacts_source_name: '',
            grace_title: response.data.ty_page_header, 
            grace_text: response.data.ty_page_text, 
            politics_url: response.data.data_policy_link,
            uid: response.data.uid
          }
          this.fieldsFormConfig = {
            email: {
              enabled: response.data.inputs.email === true ? 'true' : 'false',
              name: response.data.inputs.email_field_name,
              placeholder: response.data.inputs.email_placeholder,
              validations: ['email'],
              isRequired: response.data.email_required === true ? 'true' : 'false',
              doubleOpt: 'false',
              address: '',
              senderName: response.data.email_sender_id,
              param: 'email',
              value: ''
            },
            phone: {
              enabled: response.data.inputs.phone === true ? 'true' : 'false',
              name: response.data.inputs.phone_field_name,
              placeholder: response.data.inputs.phone_placeholder,
              validations: ['phone'],
              isRequired: response.data.phone_required === true ? 'true' : 'false',
              param: 'phone',
              value: ''
            },
            name: {
              enabled: response.data.inputs.name === true ? 'true' : 'false',
              name: response.data.inputs.name_field_name,
              placeholder: response.data.inputs.name_placeholder,
              validations: [],
              isRequired: response.data.name_required === true ? 'true' : 'false',
              param: 'name',
              value: ''
            }
          }
          this.designFormFields = {
            window_bg_color: response.data.design.form_bg_color, 
            window_title_color: response.data.design.form_header_color,
            window_description_color: response.data.design.form_desc_color, 
            fields_title_color: response.data.design.fields_header_color, 
            fields_description_color: response.data.design.fields_desc_color, 
            button_bg_color: response.data.design.btn_bg_color, 
            button_text_color: response.data.design.btn_text_color
          }
        } else {
          console.log(response)
          toast.error('Произошла ошибка')
          if (typeof callback == 'function') {
            callback('failed', [])
          }
        }
      })
      .catch((error) => {
        console.log(error)
        if (typeof callback == 'function') {
          callback('failed', [])
        }
      })
      .finally((response) => {
        this.isLoading = false
      })
   },
   async createForm() {
    this.isLoading = true
    axios
      .post('api/v1/subscription_forms', this.formData)
      .then((response) => {
        if ((response.status === 201 || response.status === 200)) {
          router.push({name: 'forms'})
          toast.success('Форма успешно создана')
        } else {
          console.log(response)
          toast.error('Произошла ошибка')
        }
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          console.log(error.response)
          let textError = ''
          for(let key in error.response.data){
            textError += (key + ': ')
            textError += error.response.data[key] + '\n\n'
          }
          toast.error(textError)
        }
      })
      .finally((response) => {
        this.isLoading = false
      })
  },
  async updateForm() {
    this.isLoading = true
    axios
      .post(`api/v1/subscription_forms/${this.formId}`, this.formData)
      .then((response) => {
        if ((response.status === 201 || response.status === 200)) {
          router.push({name: 'forms'})
          toast.success('Форма успешно обновлена')
        } else {
          console.log(response)
          toast.error('Произошла ошибка')
        }
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          console.log(error.response)
          let textError = ''
          for(let key in error.response.data){
            textError += (key + ': ')
            textError += error.response.data[key] + '\n\n'
          }
          toast.error(textError)
        }
      })
      .finally((response) => {
        this.isLoading = false
      })
  },
  async testSend() {
    this.isLoading = true
    axios
      .post('api/v1/subscription_forms/test_message', this.testSendData)
      .then((response) => {
        if ((response.status === 200 || response.status === 201)) {

        } else {
          console.log(response)
          toast.error('Произошла ошибка')
        }
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          console.log(error.response)
          let textError = ''
          for(let key in error.response.data){
            textError += (key + ': ')
            textError += error.response.data[key] + '\n\n'
          }
          toast.error(textError)
        }
      })
      .finally((response) => {
        this.isLoading = false
      })
  },
   async deleteForm(payload) {
    if (confirm("Вы действительно хотите удалить?")) {
      let formId = ''
      if (typeof payload == 'object' || payload === undefined) {
        formId  = parseInt(this.formId)
      } else {
        formId = parseInt(payload)
      }
      this.isLoading = true
      axios
        .delete(`api/v1/subscription_forms/${formId}`)
        .then((response) => {
          if ((response.status === 201 || response.status === 200)) {
            this.getFroms()
            toast.success('Форма успешно удалена')
          } else {
            console.log(response)
            toast.error('Произошла ошибка')
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(error)
        })
        .finally((response) => {
          this.isLoading = false
        })
    }
   }
  },
})